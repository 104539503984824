export const lightTheme = {
  body: "#FFF",
  text: "#58595b",
  toggleBorder: "#FFF",
  background: "#363537",
};
export const darkTheme = {
  body: "#363537",
  text: "white",
  toggleBorder: "#6B8096",
  background: "#999",
};
